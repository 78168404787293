import 'resources/css/main.css';
import React , { useEffect , useState} from 'react';
import $ from 'jquery';
import breakpoints from 'breakpoints-js';
import axios from 'axios';
import { useLocation, useNavigate} from 'react-router';

const isEmail = (str) => {
  let r3 = /^[\w~\-.]+@[\w~-]+(\.[A-Za-z0-9]+)+$/
  return r3.test(str); 
}

const Deny = () => {
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const location = useLocation();
  const history = useNavigate();
  const params = new URLSearchParams(location.search);
  const [email , setEmail] = useState();
  const [idcode , setIdcode] = useState('');
  const [errMessage , setErrMessage] = useState('');
  const [emailFix , setEmailFix] = useState(false);
  const site =!params.get('site') ? 'direct' : params.get('site');
  const media = !params.get('media') ? 0 : params.get('media');
  const [denyDsp , setDenyDsp] = useState("none");
  const code = params.get("code");
  
 

  const changeVar = (e) => {
    setEmail(e.target.value)
  }

  let valReset = () => {
    document.location.href="https://www.jwpartner.com/";
  }

  let denyProcess = async () => {
    setErrMessage('');
    if(!isEmail(email)) {
      setErrMessage('이메일을 정확하게 입력해주세요');
      return;
    }

    let data = {email : email.toLowerCase() , site : site , mediaIdxnum : media , idCode : idcode};
    let response = await axios.post(API_SERVER+'/api/denyInsert.do' , JSON.stringify(data) , {
      withCredentials: true , headers: {'Content-Type' : 'application/json'}
    }).catch((error) => {
      if(error.response) {
        alert('서버에 장애가 발생했습니다. 잠시후 다시 시도해주세요.\r\n문제 지속시에 하단 메일주소로 수신거부 부탁드립니다.');
      } else if(error.request) {
        alert('서버응답이 지연되고 있습니다. 잠시후 다시 시도해주세요.\r\n문제 지속시에 하단 메일주소로 수신거부 부탁드립니다. ');
      } else {
        alert(error.message);
      }
    })
      
    if(response) {
    let result = response.data.result;
    if(result === 'success' || result === 'duple') {
      setDenyDsp("block");
      let indate = response.data.indate;
      if(result === 'success') {
        document.getElementById('noticeBanner_p').innerHTML = '제이더블유파트너 '+indate+'<br/> 정상 수신거부 접수 되었습니다';
      } else {
        document.getElementById('noticeBanner_p').innerHTML = '제이더블유파트너 '+indate+'<br/> 이미 수신거부 접수된 메일 주소입니다.';
      }
    } else {
      alert('수신거부 처리가 지연되고 있습니다.\r\n문제 지속시에 하단 메일주소로 수신거부 부탁드립니다.');
    }

    }
  }

  let noticeClose = () => {
    setDenyDsp("none");
    document.location.href="https://www.jwpartner.com/";
  }
 

  useEffect(() => {
    if(code) {
      try{
        let decoderParam = window.atob(code).split(/[|]/);
        setEmail(decoderParam[0]);
        setIdcode(decoderParam[1]);
        setEmailFix(true);
      }catch(e) {
        console.log(e);
      }
    }

    let	$window = $(window),
      $body = $('body'),
      $wrapper = $('#wrapper'),
      $header = $('#header'),
      //$footer = $('#footer'),
      $main = $('#main'),
      $main_articles = $main.children('article');
 
      // Breakpoints.
      breakpoints({
        xlarge:   [ '1281px',  '1680px' ],
        large:    [ '981px',   '1280px' ],
        medium:   [ '737px',   '980px'  ],
        small:    [ '481px',   '736px'  ],
        xsmall:   [ '361px',   '480px'  ],
        xxsmall:  [ null,      '360px'  ]
      });

      // Play initial animations on page load.
      $window.on('load', function() {
        window.setTimeout(function() {
          $body.removeClass('is-preload');
        }, 100);
      });

      const { detect } = require('detect-browser');
      const browser = detect();
      // Fix: Flexbox min-height bug on IE.
      if (browser.name === 'ie') {

        var flexboxFixTimeoutId;

        $window.on('resize.flexbox-fix', function() {

          clearTimeout(flexboxFixTimeoutId);

          flexboxFixTimeoutId = setTimeout(function() {

            if ($wrapper.prop('scrollHeight') > $window.height())
              $wrapper.css('height', 'auto');
            else
              $wrapper.css('height', '100vh');

          }, 250);

        }).triggerHandler('resize.flexbox-fix');

      }

      // Nav.
      var $nav = $header.children('nav'),
        $nav_li = $nav.find('li');

      // Add "middle" alignment classes if we're dealing with an even number of items.
      if ($nav_li.length % 2 === 0) {
        $nav.addClass('use-middle');
        $nav_li.eq( ($nav_li.length / 2) ).addClass('is-middle');
      }

      // Main.
      var	delay = 325, locked = false;

      // Methods.
      $main._show = function(id, initial) {
        var $article = $main_articles.filter('#' + id);

        // No such article? Bail.
          if ($article.length === 0)
            return;

        // Handle lock.

        // Already locked? Speed through "show" steps w/o delays.
        if (locked || (typeof initial != 'undefined' && initial === true)) {

          // Mark as switching.
          $body.addClass('is-switching');
          // Mark as visible.
          $body.addClass('is-article-visible');

          // Deactivate all articles (just in case one's already active).
          $main_articles.removeClass('active');

          // Hide header, footer.
          $header.hide();
          

          // Show main, article.
          $main.show();
          $article.show();

          // Activate article.
          $article.addClass('active');

          // Unlock.
          locked = false;

          // Unmark as switching.
          setTimeout(function() {
            $body.removeClass('is-switching');
                  }, (initial ? 1000 : 0));

            return;
          }

          // Lock.
          locked = true;

          // Article already visible? Just swap articles.
          if ($body.hasClass('is-article-visible')) {
              // Deactivate current article.
              var $currentArticle = $main_articles.filter('.active');
              $currentArticle.removeClass('active');
              // Show article.
              setTimeout(function() {
                // Hide current article.
                $currentArticle.hide();
                // Show article.
                $article.show();
                // Activate article.
                setTimeout(function() {
                  $article.addClass('active');
                  // Window stuff.
                  $window.scrollTop(0).triggerHandler('resize.flexbox-fix');
                  // Unlock.
                  setTimeout(function() {
                    locked = false;
                  }, delay);
                }, 25);
              }, delay);
          }
          // Otherwise, handle as normal.
          else {

              // Mark as visible.
              $body.addClass('is-article-visible');
              // Show article.
              setTimeout(() => {
                // Hide header, footer.
                $header.hide();
                //$footer.hide();
                // Show main, article.
                $main.show();
                $article.show();

                // Activate article.
                setTimeout(() => {
                  $article.addClass('active');
                  // Window stuff.
                  $window.scrollTop(0).triggerHandler('resize.flexbox-fix');
                  // Unlock.
                  setTimeout(() => {
                  locked = false;
                  }, delay);
                }, 25);
              } , delay);
          }
        };

    
        

      // Scroll restoration.
      // This prevents the page from scrolling back to the top on a hashchange.
      
        if ('scrollRestoration' in history)
          history.scrollRestoration = 'manual';
        else {

          var	oldScrollPos = 0,
            scrollPos = 0,
            $htmlbody = $('html,body');

          $window
            .on('scroll', function() {

              oldScrollPos = scrollPos;
              scrollPos = $htmlbody.scrollTop();

            })
            .on('hashchange', function() {
              $window.scrollTop(oldScrollPos);
            });

        }
        

        // Initialize.
        // Hide main, articles.
        //$main.hide();
        //$main_articles.hide();
        $main._show("deny",true);
        

        // Initial article.
        
        if (location.hash !== ''	&&	location.hash !== '#')
          $window.on('load', function() {
            $main._show(location.hash.substr(1), true);
          });
  }, [location.hash , history , code] );

  return (
    <div>
      
      <div id="wrapper">
          <header id="header">
            
          </header>
          
          <div id="main">
          <div align="center" id="noticeBanner" style={{display:denyDsp}}>
        <p style={{color:'#ffffff'}} id="noticeBanner_p"/>
        <p style={{color:'#ffffff' , textAlign:'left'}}>
          <br/><br/>* 메일 수신 거부 시, 영업일 기준 최장 7일까지 반영시간이 소요될 수 있습니다. 수신 거부 접수 후에도 메일을 수신하신 경우 이점 양해 부탁드립니다.<br/>
          * 수신 거부 시에도 메일이 올 경우 1544-2049 고객센터로 연락하여 수신 거부 완료 확인 부탁드립니다.<br/>
          * 정상적으로 수신거부가 완료된 경우 이메일이 발송되지 않습니다.<br/>
          * 불편을 드려 죄송합니다.
        </p>
        <p style={{color:'#ffffff'}}> 
          <button type="button" onClick={noticeClose}>확인</button>
        </p>
      </div>
              <article id="deny">
                
                <h2 className="major">수신거부</h2>
                  <div className="fields">
                    <div className="field half">
                      <label htmlFor="email">Email</label>
                      <input type="text" name="email" id="email" value={email || ''} onChange={changeVar} readOnly={emailFix}/>
                      <span style={{marginTop:'4px',display:'block',color:'#d13535'}}>{errMessage}</span>
                    </div>
                  </div>
                  <ul className="actions" style={{marginTop:"20px"}}>
                    <li><input type="button" name="1" value="등록" className="primary" onClick={denyProcess}/></li>
                    <li><input type="button" name="2" value="취소" onClick={valReset}/></li>
                  </ul>               
                  <ul>
                    <li>수신거부를 하실 경우 소식지를 받아 보실 수 없습니다.</li>
                    <li>수신거부를 하셔도 결제관련, 이용약관 수정과 같은 사항의 메일은 받으 실 수 있습니다.</li>
                    <li>메일 수신거부시, 시스템 적용에 영업일 기준 최장 7일까지 반영시간이 소요될 수 있습니다. 수신 거부 후 메일을 수신하신 경우 양해 부탁드립니다.</li>
                    <li>수신거부 완료시까지 기다려 주세요. 완료창이 보여야 수신거부가 정상적으로 처리됩니다. 수신거부가 원활치 않을시 help@jwpartner.com 로 요청해주시면 처리해드리겠습니다.</li>
                  </ul>
              </article>
          </div>

      
          <footer id="footer">
            <p className="copyright">&copy; Jwpartner co.ltd: <a href="https://html5up.net">HTML5 UP</a>.</p>
          </footer>

      </div>
      <div id="bg"></div>
    </div>
  );
}



export default Deny;

import 'resources/css/main.css';
import React , { useEffect , useState} from 'react';
import $ from 'jquery';
import breakpoints from 'breakpoints-js';
import axios from 'axios';
import pic01 from 'resources/images/pic01.jpg';
import pic02 from 'resources/images/pic02.jpg';
import pic03 from 'resources/images/pic03.jpg';

const Main = () => {
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const [email , setEmail] = useState('');
  const [name , setName] = useState('');
  const [message , setMessage] = useState('');

  const [nameErr , setNameErr] = useState('');
  const [emailErr , setEmailErr] = useState('');
  const [messageErr , setMessageErr] = useState('');

  const [noticeDsp , setNoticeDsp] = useState('none');

  const changeVar = (e) => {
    switch(e.target.name) {
      case 'name':
        setName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'message':
        setMessage(e.target.value);
        break;
      default :
        break;
    }
  }

  const fishing_warn = () => {
    window.open('fishing-warn.html' , 'fishing-warn' , 'width=820px , height=600px , top=300px , left=300px , scrollbars=auto');
  }

  const valueCheck = () => {
    setEmail('');
    setName('');
    setMessage('');
  }

  const noticeClose = () => {
    setNoticeDsp('none');
  }


  const contactSend = async() => {
   
    setEmailErr('');
    setNameErr('');
    setMessageErr('');

    let process = true;
    if(!email) {
      console.log(email);
      setEmailErr('이메일을 입력해주세요');
      process = false;
    }

    if(!name) {
      setNameErr('이름을 입력해주세요');
      process = false;
    }

    if(!message) {
      setMessageErr('메세지를 입력해주세요');
      process = false;
    }
    
    if(!process) return;

    let data = {
      email: email , name: name, message : message
    }

    let response = await axios.post(API_SERVER+'/api/contactInsert.do' , JSON.stringify(data) , {
      withCredentials: true , headers: {'Content-Type' : 'application/json'}
    }).catch((error) => {
      if(error.response) {
        alert('서버에 장애가 발생했습니다. 잠시후 다시 시도해주세요.');
      } else if(error.request) {
        alert('서버응답이 지연되고 있습니다. 잠시후 다시 시도해주세요.');
      } else {
        alert(error.message);
      }
    });

    if(response) {
    let result = response.data.result;
    if(result === 'success') {
      setEmail('');
      setName('');
      setMessage('');
      setNoticeDsp('block');
      document.getElementById('noticeBanner_p').innerHTML ="저장되었습니다.<br/>회신이 필요할 경우 입력하신 메일주소로 답장을 드리겠습니다.";
    } else {
      alert('처리가 지연되고 있습니다. 잠시후 다시 시도해주세요.');
    }
    }
  }

  useEffect(() => {
    let	$window = $(window),
        $body = $('body'),
        $wrapper = $('#wrapper'),
        $header = $('#header'),
        $footer = $('#footer'),
        $main = $('#main'),
        $main_articles = $main.children('article');

        const location = document.location;
        const history = window.history;
        
        // Breakpoints.
        breakpoints({
          xlarge:   [ '1281px',  '1680px' ],
          large:    [ '981px',   '1280px' ],
          medium:   [ '737px',   '980px'  ],
          small:    [ '481px',   '736px'  ],
          xsmall:   [ '361px',   '480px'  ],
          xxsmall:  [ null,      '360px'  ]
        });

        // Play initial animations on page load.
        $window.on('load', function() {
          window.setTimeout(function() {
            $body.removeClass('is-preload');
          }, 100);
        });

        const { detect } = require('detect-browser');
        const browser = detect();
        // Fix: Flexbox min-height bug on IE.
        if (browser.name === 'ie') {

          var flexboxFixTimeoutId;

          $window.on('resize.flexbox-fix', function() {

            clearTimeout(flexboxFixTimeoutId);

            flexboxFixTimeoutId = setTimeout(function() {

              if ($wrapper.prop('scrollHeight') > $window.height())
                $wrapper.css('height', 'auto');
              else
                $wrapper.css('height', '100vh');

            }, 250);

          }).triggerHandler('resize.flexbox-fix');

        }

        // Nav.
        var $nav = $header.children('nav'),
          $nav_li = $nav.find('li');

        // Add "middle" alignment classes if we're dealing with an even number of items.
        if ($nav_li.length % 2 === 0) {
          $nav.addClass('use-middle');
          $nav_li.eq( ($nav_li.length / 2) ).addClass('is-middle');
        }

        // Main.
        var	delay = 325, locked = false;

        // Methods.
        $main._show = function(id, initial) {
          var $article = $main_articles.filter('#' + id);

          // No such article? Bail.
            if ($article.length === 0)
              return;

          // Handle lock.

          // Already locked? Speed through "show" steps w/o delays.
          if (locked || (typeof initial != 'undefined' && initial === true)) {

            // Mark as switching.
            $body.addClass('is-switching');
            // Mark as visible.
            $body.addClass('is-article-visible');

            // Deactivate all articles (just in case one's already active).
            $main_articles.removeClass('active');

            // Hide header, footer.
            $header.hide();
            $footer.hide();

            // Show main, article.
            $main.show();
            $article.show();

            // Activate article.
            $article.addClass('active');

            // Unlock.
            locked = false;

            // Unmark as switching.
            setTimeout(function() {
              $body.removeClass('is-switching');
                    }, (initial ? 1000 : 0));

              return;
            }

            // Lock.
            locked = true;

            // Article already visible? Just swap articles.
            if ($body.hasClass('is-article-visible')) {
                // Deactivate current article.
                var $currentArticle = $main_articles.filter('.active');
                $currentArticle.removeClass('active');
                // Show article.
                setTimeout(function() {
                  // Hide current article.
                  $currentArticle.hide();
                  // Show article.
                  $article.show();
                  // Activate article.
                  setTimeout(function() {
                    $article.addClass('active');
                    // Window stuff.
                    $window.scrollTop(0).triggerHandler('resize.flexbox-fix');
                    // Unlock.
                    setTimeout(function() {
                      locked = false;
                    }, delay);
                  }, 25);
                }, delay);
            }
            // Otherwise, handle as normal.
            else {

                // Mark as visible.
                $body.addClass('is-article-visible');
                // Show article.
                setTimeout(() => {
                  // Hide header, footer.
                  $header.hide();
                  $footer.hide();
                  // Show main, article.
                  $main.show();
                  $article.show();

                  // Activate article.
                  setTimeout(() => {
                    $article.addClass('active');
                    // Window stuff.
                    $window.scrollTop(0).triggerHandler('resize.flexbox-fix');
                    // Unlock.
                    setTimeout(() => {
                    locked = false;
                    }, delay);
                  }, 25);
                } , delay);
            }
          };

          $main._hide = function(addState) {

            var $article = $main_articles.filter('.active');

            // Article not visible? Bail.
              if (!$body.hasClass('is-article-visible')) return;

            // Add state?
            
            if (typeof addState != 'undefined'  && addState === true) history.pushState(null, null, '#');
            

            // Handle lock.

              // Already locked? Speed through "hide" steps w/o delays.
                if (locked) {
                  // Mark as switching.
                    $body.addClass('is-switching');

                  // Deactivate article.
                    $article.removeClass('active');

                  // Hide article, main.
                    $article.hide();
                    $main.hide();

                  // Show footer, header.
                    $footer.show();
                    $header.show();

                  // Unmark as visible.
                    $body.removeClass('is-article-visible');

                  // Unlock.
                    locked = false;

                  // Unmark as switching.
                    $body.removeClass('is-switching');

                  // Window stuff.
                    $window
                      .scrollTop(0)
                      .triggerHandler('resize.flexbox-fix');
                  return;
                }

              // Lock.
                locked = true;

            // Deactivate article.
              $article.removeClass('active');

            // Hide article.
              setTimeout(function() {

                // Hide article, main.
                  $article.hide();
                  $main.hide();

                // Show footer, header.
                  $footer.show();
                  $header.show();

                // Unmark as visible.
                  setTimeout(function() {

                    $body.removeClass('is-article-visible');

                    // Window stuff.
                      $window
                        .scrollTop(0)
                        .triggerHandler('resize.flexbox-fix');

                    // Unlock.
                      setTimeout(function() {
                        locked = false;
                      }, delay);

                  }, 25);

              }, delay);


          };

        // Articles.
          $main_articles.each(function() {

            var $this = $(this);

            // Close.
              
              $('<div class="close">Close</div>')
                .appendTo($this)
                .on('click', function() {
                  location.hash = '';
                });
              

            // Prevent clicks from inside article from bubbling.
              $this.on('click', function(event) {
                if(!(event.target.tagName === 'INPUT' && event.target.type ==='button')) {
                  event.stopPropagation();
                }
                
              });
          });

        // Events.
          $body.on('click', function(event) {
            // Article visible? Hide.
            if(!(event.target.tagName === 'INPUT' && event.target.type ==='button')) {
              if ($body.hasClass('is-article-visible'))
                $main._hide(true);
            }
          });

          $window.on('keyup', function(event) {
            switch (event.keyCode) {
              case 27:
                // Article visible? Hide.
                  if ($body.hasClass('is-article-visible'))
                    $main._hide(true);
                break;
              default:
                break;

            }

          });
          
          $window.on('hashchange', function(event) {

            // Empty hash?
              if (location.hash === ''
              ||	location.hash === '#') {

                // Prevent default.
                  event.preventDefault();
                  event.stopPropagation();

                // Hide.
                  $main._hide();

              }

            // Otherwise, check for a matching article.
              else if ($main_articles.filter(location.hash).length > 0) {

                // Prevent default.
                  event.preventDefault();
                  event.stopPropagation();

                // Show article.
                  $main._show(location.hash.substr(1));

              }

          });
        

        // Scroll restoration.
        // This prevents the page from scrolling back to the top on a hashchange.
        
          if ('scrollRestoration' in history)
            history.scrollRestoration = 'manual';
          else {

            var	oldScrollPos = 0,
              scrollPos = 0,
              $htmlbody = $('html,body');

            $window
              .on('scroll', function() {

                oldScrollPos = scrollPos;
                scrollPos = $htmlbody.scrollTop();

              })
              .on('hashchange', function() {
                $window.scrollTop(oldScrollPos);
              });

          }
          

          // Initialize.
          // Hide main, articles.
          $main.hide();
          $main_articles.hide();

          // Initial article.
          
          if (location.hash !== ''	&&	location.hash !== '#')
            $window.on('load', function() {
              $main._show(location.hash.substr(1), true);
            });
  } , []);

  return (
      <div>
      <div id="wrapper">
            <header id="header">
              <div className="logo">
                <span className="icon fa-gem"></span>
              </div>
              <div className="content">
                <div className="inner">
                  <h1>Jwpartner</h1>
                  <p>Join us with your dreams.<br />
                  We make your dreams come true. Jwpartner.</p>
                  <p>Leading e-MAIL Marketing Enterprise</p>
                </div>
              </div>
              <nav>
                <ul>
                  <li><a href="#intro">Intro</a></li>
                  <li><a href="#work">Work</a></li>
                  <li><a href="#about">History</a></li>
                  <li><a href="#contact">Contact</a></li>
                  <li><a href="#location">Location</a></li>
                </ul>
              </nav>
            </header>
  
          
            <div id="main">
                <div align="center" id="noticeBanner" style={{display:noticeDsp}}>
                  <p style={{color:'#ffffff'}} id="noticeBanner_p"/>
                  <p style={{color:'#ffffff'}}> 
                    <input type="button" onClick={noticeClose} value="확인"/>
                  </p>
                </div>
              
                <article id="intro">
                  <h2 className="major">Intro</h2>
                  <span className="image main"><img src={pic01} alt="" /></span>
                  <p>㈜제이더블유는 2001년에 설립된 회사입니다.<br/>그동안 게임 개발 및 솔루션 제작과 미아방지 솔루션, 인터넷 마케팅 등의 사업을 진행해온 인터넷과 함께 시작한 회사입니다.</p>
                  <p>회사의 기업 이념인 “Dreams come true”와 같이 우리 모두가 꿈꾸는 모든 것들을 현실로 만들고자 노력하겠습니다.</p>
                  <p>그동안 많은 온라인 마케팅사들과의 협업, 자사만의 독창적인 기술 노하우로 여러분들의 꿈을 함께 실현하는 회사가 되도록 노력하겠습니다.</p>
                  <p>변화하는 인터넷 세상의 중심에 서는 그날까지 여러분들의 많은 사랑과 격려 부탁드립니다.</p>
                </article>
  
              
                <article id="work">
                  <h2 className="major">Work</h2>
                  <span className="image main"><img src={pic02} alt="" /></span>
                  <p>온라인 광고 대행 사업<br/>
                  현대자동차, 이마트, 필라코리아, 해피랜드에프앤씨, 이랜드, 초록우산 어린이재단 등
                  매리츠화재, LIG손해보험, 미래에셋생명,동부화재, 동양생명 등
                  </p>
                  <p>인터넷 웹사이트 운영<br/>
                  현재 450만명을 보유한 게임사이트와 커뮤니트 사이트 등 다수의 사이트 운영
                  10년 이상의 사이트 운영 경험과 마케팅 노하우 보유
                  </p>
                  <p>
                  미아ㆍ실종방지 솔루션 개발 및 구축<br/>
                  미아,실종방지 솔루션 IT융합 신기술 사업 선정(지식경제부, 정보통신산업진흥원)
                  </p>
                  <p>
                  주요 수상이력 : 지식경제부 2012 IT 융합 기술혁신사업 선정.<br/>
                          한경닷컴 브랜드 대상 2012 수상
                  </p>
                </article>
  
              
                <article id="about">
                  <h2 className="major">History</h2>
                  <span className="image main"><img src={pic03} alt="" /></span>
                  <div>2001년 12월 : 회사 설립</div>
                  <div className="history">
                    <div>2002년 03월 :</div>
                    <div style={{marginLeft:'3px'}}> 인터넷 보드 게임개발 및 사이트 운영 시작<br/>현재 회원 수 220만명</div>
                  </div>
                  <div className="history">
                    2002년 10월 : 하나포스닷컴, 코리아닷컴 게임 공급 및 서비스
                  </div>
                  <div className="history">
                    2003년 05월 : 한국일보  웹게임부분 수상
                  </div>
                  <div className="history">
                    2003년 10월 : 스포츠조선 온라인게임 솔루션부문 수상
                  </div>
                  <div className="history">
                    2004년 07월 : 커뮤니티 채팅사이트 오픈 운영
                  </div>
                  <div className="history">
                    2004년 12월 : 고소영 화보집 “산토리니” 제작 및 독점 서비스
                  </div>
                  <div className="history">
                    2005년 11월 : 스포츠신문 “굿데이” 게임 공급
                  </div>
                  <div className="history">
                    2007년 03월 : 신동아화재 등 자동차보험사 전략적 마케팅 제휴
                  </div>
                  <div className="history">
                    2008년 12월 : 미래에셋생명 DB 마케팅 시스템 구축 및 운영
                  </div>
                  <div className="history">
                    2010년 12월 : 결혼정보 매칭 시스템 구축 및 운영
                  </div>
                  <div className="history">
                    <div>2011년 11월 :</div>
                    <div style={{marginLeft:'3px'}}>데이터 입출력이 가능한 2차원바코드 정보처리<br/>시스템 특허 등록 ( 등록번호 : 10-1084545)</div>
                  </div>
                  <div className="history">     
                    <div >2012년 05월 :</div>
                    <div style={{marginLeft:'3px'}}>미아·실종방지 스마트 제품 개발 및 출시<br/>한경닷컴 (2012년 브랜드 대상) 수상</div>
                  </div>
                  <div className="history">  
                    <div >2012년 08월 :</div> 
                    <div style={{marginLeft:'3px'}}>미아,실종방지 제품 IT융합 신기술 사업 선정<br/>(지식경제부, 정보통신산업진흥원)</div>
                  </div>
                  <div className="history">
                    2012년 12월 : 메리츠화재, LIG손보, 어린이재단 스마트 제품 공급
                  </div>
                  <div className="history">
                    2013년 05월 : 미아,실종방지 스마트 의류 출시 (해피랜드 등)
                  </div>
                  <div className="history">
                    2013년 10월 : 필라코리아 “필라키즈” 아동복 솔루션 제공
                  </div>
                  <div className="history">
                    2017년 10월 : Gold Mail 런칭
                  </div>
                </article>
  

                <article id="contact">
								<h2 className="major">Contact</h2>
								<form>
									<div className="fields">
										<div className="field half">
											<label htmlFor="name">Name</label>
											<input type="text" name="name" id="name" value={name} onChange={changeVar}/>
                      <span style={{marginTop:'4px',display:'block',color:'#d13535'}}>{nameErr}</span>
										</div>
										<div className="field half">
											<label htmlFor="email">Email</label>
											<input type="text" name="email" id="email" value={email} onChange={changeVar}/>
                      <span style={{marginTop:'4px',display:'block',color:'#d13535'}}>{emailErr}</span>
										</div>
										<div className="field">
											<label htmlFor="message">Message</label>
											<textarea name="message" id="message" rows="4" onChange={changeVar} value={message}></textarea>
                      <span style={{marginTop:'4px',display:'block',color:'#d13535'}}>{messageErr}</span>
										</div>
									</div>
									<ul className="actions">
										<li><input type="button" value="Send Message" className="primary" onClick={contactSend}/></li>
										<li><input type="button" value="Reset" onClick={valueCheck}/></li>
									</ul>
								</form>
								<ul className="icons">
									
								</ul>
							</article>

                <article id="location">
                  <h2 className="major">Location</h2>
                  <span className="image main"><img src={pic03} alt="" /></span>
                  <div>경기도 시흥시 서울대학로 59-69 배곧테크노밸리 330호</div>
                </article>
  
            </div>
           
  
        
            <footer id="footer">
              <p className="copyright">(주)제이더블유파트너 | 대표이사 : 박우철 | 경기도 시흥시 서울대학로 59-69 배곧테크노밸리 330호 | 사업자등록번호 : 119-81-74843
              <br/>문의전화 : 1544-2049 (상담시간 ㅣ 월~금 10:00 ~ 17:00) | FAX : 02-846-1876 . Design: <a href="https://html5up.net">HTML5 UP</a>. | <span onClick={fishing_warn}>사업자 도용관련</span></p>
            </footer>
  
        </div>
  
      
        <div id="bg"></div>
        </div>
    );
}

export default Main;

import React from 'react'
import {BrowserRouter , Route , Routes} from 'react-router-dom' 
import Main from './Main'
import Deny from './Deny'
  class App extends React.Component {
    render(){
      return (
         <BrowserRouter>
            <Routes>
               <Route path="/" element={<Main/>} />
               <Route path="/mail_noletter/*"  element={<Deny/>} />
            </Routes>
          </BrowserRouter>   
      )
    }
  }
  export default App;
